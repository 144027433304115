<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot v-if="data.length !== 0" name="columns">
          <th v-for="column in columns" :key="column" :class="{ 'd-none d-md-table-cell' : mobileHideColumns.includes(column)}">{{ column ? $t('column.' + column.toLowerCase()) : '' }}</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td v-for="(column, index) in columns" :key="index" v-if="hasValue(item, column)" :class="{ 'd-none d-md-table-cell' : mobileHideColumns.includes(column)}">
            <div>
              {{ itemValue(item, column) }}
            </div>
          </td>
          <td v-if="actions.includes('edit')" class="table-action">
            <base-button class="btn-action" slot="footer" type="secondary" fill @click="editAction(item[idField])"><i
                class="tim-icons icon-pencil"></i></base-button>
          </td>
          <td v-if="actions.includes('delete')" class="table-action">
            <base-button class="btn-action" slot="footer" type="danger" fill @click="deleteAction(item[idField])"><i
                class="tim-icons icon-trash-simple"></i></base-button>
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
import Modal from '@/components/Modal';

export default {
  name: 'base-table',
  components: {
    Modal
  },
  created() {
  },
  data() {
    return {
      qrModalVisible: false,
      qrData: ''
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },
    mobileHideColumns: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data"
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type"
    },
    theadClasses: {
      type: String,
      default: '',
      description: "<thead> css classes"
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: "<tbody> css classes"
    },
    idField: {
      type: String,
      default: 'id',
      description: "Id field for items"
    },
    actions: {
      type: Array,
      default: () => [],
      description: "Table actions"
    },
    editAction: {
      default: () => [],
    },
    editActionRoles: {
      default: () => [],
    },
    deleteAction: {
      default: () => [],
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    qrAction(id) {
      this.qrModalVisible = true
      this.qrData = id
    }
  }
};
</script>
<style>

.table-action {
  width: 1%;
  white-space: nowrap;
}

.modal-qr .modal-content {
  width: fit-content;
}

.modal-qr {
  background: beige !important;
}
</style>
