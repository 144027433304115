<template>
  <nav class="navbar navbar-expand-lg navbar-absolute" :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
          <button type="button" class="navbar-toggler" aria-label="Navbar toggle button" @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <span class="navbar-brand">{{ $t('sidebar.' + routeName.toLowerCase()) }}</span>
      </div>

      <div class="show">
        <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">

          <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item"
            menu-classes="dropdown-navbar">
            <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
              <div class="photo">
                <img src="@/assets/img/anime3.png">
              </div>
            </a>
            <li class="nav-link">
              <a href="#" class="nav-item dropdown-item" @click="changePasswordModal = true">Сменить пароль</a>
            </li>
            <div class="dropdown-divider"></div>
            <li class="nav-link">
              <a href="#" class="nav-item dropdown-item" @click="logout">Выход</a>
            </li>
          </base-dropdown>
        </ul>
      </div>
    </div>
    <modal :show.sync="changePasswordModal" class="modal-change-password" id="changePasswordModal" :centered="false"
      :show-close="true">
      <base-input type="password" minlength="8" label="Старый пароль" placeholder="Старый пароль" v-model="changePasswordModel.old_password">
      </base-input>
      <base-input type="password" minlength="8" label="Новый пароль" placeholder="Новый пароль" v-model="changePasswordModel.new_password">
      </base-input>
      <base-input type="password" minlength="8" label="Новый пароль повторно" placeholder="Новый пароль повторно" v-model="new_password2">
      </base-input>
      <base-button slot="footer" type="submit" fill @click="changePassword" :disabled="loading">Сменить
        пароль</base-button>
      <div class="alert alert-warning alert-dismissible fade show mt-5 d-none" role="alert" id="alert_1">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </modal>
  </nav>
</template>
<script>
import ChangePassword from '../../models/change_password';
import UserService from '../../services/user.service';
import { CollapseTransition } from 'vue2-transitions';
import Modal from '@/components/Modal';

export default {
  components: {
    CollapseTransition,
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      loading: false,
      showMenu: true,
      searchModalVisible: false,
      changePasswordModal: false,
      searchQuery: '',
      new_password2: '',
      changePasswordModel: new ChangePassword(null, '', ''),
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
    changePassword() {
      if (this.new_password2 !== this.changePasswordModel.new_password) {
        let alert_1 = document.querySelector("#alert_1");
        alert_1.classList.remove("d-none");
        alert_1.innerHTML = "Пароли не совпадают";
        return
      }
      this.loading = true;
      UserService.changePassword(this.changePasswordModel).then(
        () => {
          this.loading = false;
          this.changePasswordModal = false;
          this.changePasswordModel = new ChangePassword(null, '', ''); 
        },
        error => {
          this.loading = false;
          let alert_1 = document.querySelector("#alert_1");
          alert_1.classList.remove("d-none");
          alert_1.innerHTML = "Ошибка смены пароля";
        }
      );
    }
  }
};
</script>
<style>
.dropdown-toggle::after {
  content: none !important
}
</style>
