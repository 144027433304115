import axios from 'axios';
import AuthService from './auth.service';
import authHeader from './auth-header';
import config from '@/config';
import store from "../store";


const instance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    const originalRequest = error.config
    store.dispatch('auth/refresh').then(
      () => {
        error.config.headers = authHeader();
        error.config.baseURL = undefined;
        return instance.request(error.config);
      },
      error => {
        AuthService.logout()
      }
    );
  } else {
    return Promise.reject(error)
  }
});

class UserService {
  save(user) {
    return instance.post('user', user, { headers: authHeader() });
  }

  getBoard() {
    return instance.get('user/all', { headers: authHeader() });
  }

  delete(id) {
    return instance.delete('user/' + id, { headers: authHeader() });
  }

  get(id) {
    return instance.get('user/' + id, { headers: authHeader() });
  }

  changePassword(changePasswordModel) {
    return instance.post('change-password', changePasswordModel, { headers: authHeader() });
  }

  getUserOrganizations() {
    return instance.post('user/organizations', '', { headers: authHeader() });
  }

  roles() {
    return instance.get('roles', { headers: authHeader() });
  }
}

export default new UserService();
