import axios from 'axios';
import router from "../router/index";
import config from '@/config';


const instance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

class AuthService {
  login(user) {
    return instance
      .post('login', {
        login: user.login,
        password: user.password
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  refresh() {
    return instance
      .post('refresh', JSON.parse(localStorage.getItem('user')))
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    router.push('/login')
  }
}

export default new AuthService();
