import VueRouter from "vue-router";
import routes from "./routes";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  if ((to.meta.requiresAuth === undefined || to.meta.requiresAuth) && !localStorage.getItem('user')) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    next({ name: 'Login', query: { redirect: to.fullPath } })
  }
  else next()
})

export default router;
